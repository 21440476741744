<template>
<base-popup
    :popup-model="show"
    title="View links"
    :valid="!valid"
    :width="50"
    @onSaveButtonClick="saveLinkInsertionHandler"
    @onCancelButtonClick="closePopupHandler"
>
  <v-form :ref="formRef" v-model="valid" lazy-validation>
  <v-row class="mt-5 mb-0 mr-3 ml-3" no-gutters>
    <template v-for="(item, key) in links">
      <v-col cols="12" md="4">
        <v-text-field
            v-model="item.anchorText"
            :rules="requiredFieldRule"
            :readonly="disabled"
            label="Anchor Text"
            class="mr-2"
            outlined
            dense
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
            v-model="item.url"
            :rules="requiredFieldRule"
            :readonly="disabled"
            label="URL"
            class="mr-2"
            outlined
            dense
        />
      </v-col>
      <v-col cols="12" md="2" v-if="!disabled">
        <v-btn
            v-if="key !==0"
            color="error"
            icon
            @click="linkRowRemoveHandler(key)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
            v-if="links.length === key + 1 && !(links.length >= 10)"
            color="primary"
            fab
            x-small
            @click="linkRowHandler()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </template>
  </v-row>
  </v-form>
</base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";

export default {
  name: "LinkInsertionsPopup",
  components: {BasePopup},
  mixins: [Popup],
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      links: []
    }
  },
  watch: {
    item(data) {
      if(data && data.linkInsertions) {
        if(this.disabled) {
          this.links = data.linkInsertions;
        } else {
          this.links = data.linkInsertions.length > 0 ? _.cloneDeep(data.linkInsertions) : [{anchorText: '', urL: ''}];
        }
      }
    }
  },
  methods: {
    linkRowHandler() {
      if (this.links.length >= 10) return;
      this.links.push({anchorText: '', url: ''});
    },
    linkRowRemoveHandler(index) {
      this.links.splice(index, 1)
    },
    saveLinkInsertionHandler() {
      if(!this.$refs.formRef.validate()) {
        return;
      }

      this.$emit('updateLinkInsertionHandler', {productId: this.item.id, linkInsertions: this.links})
    }
  }
}
</script>

<style scoped>

</style>