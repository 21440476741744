<template>
  <v-data-table
      :headers="headers"
      :items="dueData"
      :items-per-page="5"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        Due Payments
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      Due table is empty!
    </template>
    <template v-slot:item.paymentStatus="{ item }">
      <g-payment-status-chip v-if="item.paymentStatus === 100"/>
      <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
    </template>
    <template v-slot:item.date="{ item }">
      {{getDate(item.date)}}
    </template>
    <template v-slot:item.totalAmount="{ item }">
      ${{item.totalAmount}}
    </template>
  </v-data-table>
</template>

<script>
import GPaymentStatusChip from "../GPaymentStatusChip";
import DateHelper from "../mixins/DateHelper";
export default {
  name: "DueDataTable",
  components: {GPaymentStatusChip},
  mixins: [DateHelper],
  data() {
    return {
      headers: [
        {
          text: 'OrderID',
          value: 'orderId'
        },
        {
          text: 'Created At',
          value: 'date'
        },
        {
          text: 'Payment Status',
          value: 'paymentStatus'
        },
        {
          text: 'Total Amount',
          value: 'totalAmount',
          align: 'right'
        }
      ]
    }
  },
  props: {
    dueData: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>