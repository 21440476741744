<template>
  <div>
    <v-card class="ma-3">
      <g-overlay :is-relay="isOverlay"/>
      <page-progress v-bind:is-loading="isLoading"/>
      <page-title icon="mdi-cart-outline" title="Make a Order" :show-button="true" btn-link="/sale-counters"/>
      <v-container class="pt-0" fluid>
        <v-row>
          <v-col cols="12" md="5" sm="12" xl="5" xs="12">
            <v-card>
              <v-card-text>
                <v-form ref="orderFormRef" v-model="orderValid" lazy-validation>
                <v-row dense no-gutters>
                   <v-col cols="12">
                      <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="order.date"
                              label="Date"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                          />
                        </template>
                        <v-date-picker
                            v-model="order.date"
                            no-title
                            @input="menu1 = false"
                        />
                      </v-menu>
                  </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="order.name"
                          dense
                          label="Name"
                          outlined
                      />
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-text-field
                          v-if="!isExists"
                          v-model="order.email"
                          :rules="requiredEmailRule"
                          dense
                          :label="!showClientInnerIcon ? 'Email' : ''"
                          outlined
                          class="mr-2"
                          :prepend-inner-icon="showClientInnerIcon"
                          @blur="fixClientHandler(order.email)"
                      />
                      <g-p-auto-complete
                          v-if="isExists"
                          :dense="true"
                          :outlined="true"
                          v-model="order.email"
                          :validation="true"
                          apiPath="sales-counter/get-all-email"
                          item-text="email"
                          item-value="email"
                          :search-label="!showClientInnerIcon ? 'Select a Email' : ''"
                          :inner-icon="showClientInnerIcon"
                          @clearSearchResult="handleClearSearchResultEmail"
                          @selectSearchResult="onSelectionsEmailHandler"
                          class="mr-2"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-checkbox
                          class="checkbox-mr-top"
                          v-model="isExists"
                          label="Is Exists?"
                      />
                    </v-col>
                    <v-col cols="12">
                      <!--<v-select
                          v-model="order.billingType"
                          :items="['Guest Posting on', 'Link Insertion on', 'Home Page Link Insertion on', 'Article Writing and Publishing on', ]"
                          :rules="requiredFieldRule"
                          label="Billing Type"
                          outlined
                          dense
                          :disabled="carts.length > 0"
                          @change="fixClientBillingTypeHandler"
                      />-->
                      <v-select
                              v-model="order.billingType"
                              :items="['Guest Posting on', 'Link Insertion on', 'Home Page Link Insertion on', 'Article Writing and Publishing on', ]"
                              :rules="requiredFieldRule"
                              label="Billing Type"
                              outlined
                              dense
                              @change="fixClientBillingTypeHandler"
                      />
                    </v-col>
                  <v-col cols="12" v-if="order.billingType === 'Home Page Link Insertion on'">
                    <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="order.linkExpiryDate"
                            label="Link Expiry Date"
                            dense
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        />
                      </template>
                      <v-date-picker
                          v-model="order.linkExpiryDate"
                          @change="saveOrderExpiryDate"
                      />
<!--                      :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"-->
                    </v-menu>
                  </v-col>
                  <v-col cols="12" v-if="orderDueInfo.length > 0">
                    <due-data-table :due-data="orderDueInfo"/>
                  </v-col>
                </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="12" xl="7" xs="12">
            <v-card>
              <v-card-text>
                <v-form ref="cartFormRef" v-model="formValid" lazy-validation>
                  <v-row dense no-gutters>
                    <v-col cols="12" md="6" sm="12" xs="12">
                      <g-p-auto-complete
                          :dense="true"
                          :outlined="true"
                          :search-value="product.site"
                          :status=true
                          :validation="false"
                          apiPath="sites"
                          item-text="siteUrl"
                          search-label="Select a Site"
                          sort-by="site_url"
                          @clearSearchResult="handleClearSearchResult"
                          @selectSearchResult="onSelections"
                      />
                    </v-col>
                    <v-col cols="6" md="6" sm="12" xs="12">
                      <add-button
                          text="Add Site"
                          @onButtonClick="openSitePopup"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="product.customEntityName"
                          label="Custom Entity Name"
                          class="mr-2"
                          outlined
                          dense
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          :disabled="fixClient && Object.keys(fixClient).length > 0"
                          v-model="product.price"
                          :rules="priceRule"
                          class="right-align-text"
                          label="Price"
                          onclick="this.select()"
                          @input="inputPriceHandler()"
                          type="number"
                          outlined
                          dense
                      />
                    </v-col>
                    <v-col cols="12" md="12" v-if="order.billingType === 'Article Writing and Publishing on'">
                      <v-text-field
                          v-model="product.articleWritingPrice"
                          :rules="numberRule"
                          class="right-align-text"
                          label="Article Writing Price"
                          onclick="this.select()"
                          @input="inputPriceHandler()"
                          type="number"
                          outlined
                          dense
                      />
                    </v-col>
                    <template v-for="(item, key) in linkInsertions" v-if="order.billingType === 'Link Insertion on' || order.billingType ==='Home Page Link Insertion on'">
                      <v-col cols="12" md="4">
                        <v-text-field
                            v-model="item.anchorText"
                            :rules="requiredFieldRule"
                            label="Anchor Text"
                            class="mr-2"
                            outlined
                            dense
                        />
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-text-field
                            v-model="item.url"
                            :rules="requiredFieldRule"
                            label="URL"
                            class="mr-2"
                            outlined
                            dense
                        />
                      </v-col>
                      <v-col cols="12" md="1">
                        <v-btn
                            v-if="key !==0"
                            color="error"
                            icon
                            @click="linkInsertionRowRemoveHandler(key)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="linkInsertions.length === key + 1 && !(linkInsertions.length >= 10)"
                            color="primary"
                            fab
                            x-small
                            @click="linkInsertionRowHandler()"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                    <v-col cols="12">
                      <v-textarea
                          v-model="product.liveLink"
                          label="Live Link"
                          :rules="requiredFieldRule"
                          outlined
                          dense
                          rows="2"
                          @change="checkLiveLinks()"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" style="margin-top: -25px">
                      <v-checkbox
                              v-model="product.isAllowedLinkInsert"
                              label="Is allowed link insert ?"
                              color="indigo"
                              hide-details
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-checkbox
                          class="mb-5"
                          v-model="isCheckedAdultPrice"
                          :disabled="!product.siteId"
                          label="Other Post Link Price"
                          color="indigo"
                          hide-details
                          @change="onCheckboxAdultHandler()"
                      />
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <v-text-field
                          v-model="product.total"
                          :rules="priceRule"
                          class="right-align-text"
                          dense
                          label="Total"
                          outlined
                          readonly
                          type="number"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <v-card-actions>
                  <v-spacer/>
                  <add-button
                      text="Add to Cart"
                      @onButtonClick="addToCartHandler"
                  />
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12" xl="7" xs="12">
           <v-card>
             <v-card-text>
               <v-checkbox v-model="isAddBulkLink" label="Bulk Link"/>
               <template v-if="isAddBulkLink">
                 <v-checkbox
                     class="mb-5"
                     v-model="isBulkCheckedAdultPrice"
                     label="Other Post Link Price"
                     color="indigo"
                 />
                 <v-textarea
                     v-model="bulkLiveLink"
                     label="Bulk Link"
                     placeholder="Paste Live Link Here....."
                     outlined
                     dense
                     rows="4"
                     @input="bulkLiveLinkHandler($event)"
                 />
               </template>
             </v-card-text>
           </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12" xl="12" xs="12">
            <v-form ref="cartFooterForm" v-model="formValid" lazy-validation>
              <v-card>
              <v-data-table
                  :headers="headers"
                  :items="carts"
                  hide-default-footer
                  :disable-pagination="true"
              >
                <template v-slot:footer>
                  <v-divider/>
                  <v-toolbar flat>
                    <v-spacer/>
                    <cancel-button @onButtonClick="onCancelInvoice"/>
                    <confirm-button
                        text="Confirm"
                        :disabled="!carts.length"
                        @onButtonClick="onConfirmHandler"
                    />
                  </v-toolbar>
                </template>
                <template v-slot:body.append>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Sub Total</h2>
                    </td>
                    <td style="text-align: right"><h2>${{ subTotal }}</h2></td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Discount</h2>
                    </td>
                    <td style="text-align: right">
                      <v-text-field
                          v-model="order.discount"
                          :rules="numberRule"
                          label="Discount"
                          type="number"
                          onclick="this.select()"
                          class="right-align-text discount-field mt-2 mb-1"
                          dense
                          outlined
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Grand Total</h2>
                    </td>
                    <td style="text-align: right"><h2>${{ grandTotal }}</h2></td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Order From</h2>
                    </td>
                    <td style="text-align: right">
                      <g-p-auto-complete
                              :dense="true"
                              :outlined="true"
                              :search-value="orderEmails"
                              :status=true
                              :validation="true"
                              apiPath="order-emails"
                              class="discount-field mt-2 mb-1"
                              @clearSearchResult="handleClearOrderEmailSearchResult"
                              @selectSearchResult="onSelectionsOrderEmail"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Is Prepayment?</h2>
                    </td>
                    <td style="text-align: right">
                      <v-checkbox
                          v-model="order.isPrepayment"
                      />
                    </td>
                    <td></td>
                  </tr>
                </template>
                <template v-slot:top>
                  <v-toolbar dense flat>
                    Carts
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Cart is empty!
                </template>
                <template v-slot:item.liveLink={item}>
                  <v-chip
                      class="ma-2"
                      color="green"
                      link
                      text-color="white"
                      @click.prevent.stop="liveLinkHandler(item)"
                  >
                    View Live Link
                  </v-chip>
                </template>
                <template v-slot:item.isAllowedLinkInsert={item}>
                  <v-checkbox
                          v-model="item.isAllowedLinkInsert"
                          color="indigo"
                          hide-details
                  />
                </template>
                <template v-slot:item.linkInsertions={item}>
                  <v-chip
                      v-if="order.billingType === 'Link Insertion on' || order.billingType === 'Home Page Link Insertion on'"
                      class="ma-2"
                      color="lime"
                      link
                      text-color="white"
                      @click.prevent.stop="linkInsertionPopupHandler(item)"
                  >
                    View Links
                  </v-chip>
                </template>
                <template v-slot:item.price={item}>
                  {{ getAmountWithSymbol(item.price) }}
<!--                  <div class="price-field">
                    <v-text-field
                        :disabled="fixClient && Object.keys(fixClient).length > 0"
                        :hide-details="true"
                        :value="item.price"
                        class="right-align-text"
                        dense
                        onclick="this.select()"
                        outlined
                        type="number"
                        @input="onPriceHandler(item.id, $event)"
                    />
                  </div>-->
                </template>
                <template v-slot:item.adultPrice={item}>
                  {{ getAmountWithSymbol(item.adultPrice) }}
                </template>
                <template v-slot:item.articleWritingPrice={item}>
                  {{ getAmountWithSymbol(item.articleWritingPrice) }}
                </template>
                <template v-slot:item.total={item}>
                  {{ getAmountWithSymbol(item.total) }}
                </template>
                <template v-slot:item.actions={item}>
                  <v-btn
                      color="error"
                      icon
                      @click="confirmToDeleteItem(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
            </v-form>
          </v-col>
        </v-row>
        <view-live-link
            :item="currentItem"
            :show="showLiveLink"
            @closePopupHandler="closePopupHandler"
            @onHandleLinkEditable ="onHandleLinkEditable"
        />
        <link-insertions-popup
            :item="currentItem"
            :show="showLinkInsertionPopup"
            @closePopupHandler="closePopupHandler"
            @updateLinkInsertionHandler ="updateLinkInsertionHandler"
        />
        <confirm-popup
            :show="showItemDeleteConfirm"
            confirm-event="onConfirmDelete"
            @closePopupHandler="showItemDeleteConfirm = false"
            @onConfirmDelete="confirmDelete()"
        >
        <span
        >Are you sure to remove
        <b>{{ this.deleteItem ? this.deleteItem.siteUrl : "" }}</b> from cart?</span
        >
        </confirm-popup>
        <confirm-popup
            :show="showOrderConfirm"
            confirm-event="onConfirmOrder"
            @closePopupHandler="showOrderConfirm = false"
            @onConfirmOrder="onConfirmHandler(true)"
        >
          <div>
        <span>Are you sure to confirm the order amount of
          <b>${{ grandTotal }}, </b>
          from <i>{{ order.email && order.email ? order.email : " " }}</i> ?
       </span>
          </div>
        </confirm-popup>
      </v-container>
      <site-popup
          :basic-data="basicData"
          :show="showSitePopup"
          @closePopupHandler="closeSitePopupHandler"
          @saveItemHandler="saveSiteItemHandler"
      />
    </v-card>
  </div>
</template>

<script>
import OrderMixins from "./mixins/OrderMixins";
import GOverlay from "./GOverlay";
import LinkInsertionsPopup from "./popups/LinkInsertionsPopup";
import DueDataTable from "./counters/DueDataTable";
import SitePopup from "./popups/SitePopup";
import Sites from "../services/Sites";
export default {
  name: "GSalesCounter",
  components: {
    SitePopup,
    DueDataTable,
    LinkInsertionsPopup,
    GOverlay
  },
  mixins: [OrderMixins],
  data() {
    return {
      showSitePopup: false,
      basicData: {
        pa: 0,
        da: 0,
        dr: 0,
        ur: 0,
        traffic: 0,
        backLinks: 0,
        adminPrice: 0,
        price: 0,
        resellerPrice: 0,
        linkPrice: 0,
        adultPrice: 0,
        follow: true,
        isActive: true,
        isCheckBrokenLink: true,
        googleNews: true,
        casino: true,
        nicheCategories: []
      }
    }
  },
  methods: {
    openSitePopup() {
      this.showSitePopup = true;
    },
    closeSitePopupHandler() {
      this.showSitePopup = false;
    },
    saveSiteItemHandler(item) {
      Sites.store(item)
          .then(({ data: { message } }) => {
            this.setSnackBar({ text: message, type: "info" });
          })
          .catch(({ response: { data } }) => {
            this.setSnackBar({
              text: data.message,
              type: "error",
            });
          })
          .finally(() => {
            this.showSitePopup = false;
          })
    }
  }
}
</script>

<style lang="scss">
.price-field .v-input {
  max-width: 200px;
  float: right;
  align-items: center;
}

.discount-field .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
  display: none;
}
.checkbox-mr-top{
  margin-top: 2px !important;
}

.v-chip.v-size--default{
  height: 40px !important;
}
</style>