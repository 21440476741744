<template>
<base-popup
    :popup-model="show"
    title="Live Links"
    :valid="!valid"
    :show-save-button="false"
    @onCancelButtonClick="closeHandler"
>
  <div class="mt-5 mb-5">
    <v-textarea
        v-model="currentItem.liveLink"
        label="Live Link"
        :rules="requiredFieldRule"
        :readonly="disabled"
        outlined
        dense
        rows="5"
        @blur="onHandleLinkEditable($event)"
    />
  </div>
</base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
export default {
  name: "ViewLiveLink",
  mixins: [PopupMixin],
  components: {BasePopup},
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    closeHandler() {
      this.$emit('closePopupHandler');
    },
    onHandleLinkEditable(event) {
      const link = event.target.value;
      if(!link) return;
      this.$emit('onHandleLinkEditable', {id: this.currentItem.id, link});
    }
  }
}
</script>

<style scoped>

</style>